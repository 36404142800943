<template>
  <section class="contact-section" id="contacts">
    <div class="container">
      <div class="section-title">
        <h2>Contact <span>Us</span></h2>
      </div>
      <div class="contact-info">
        <div class="address">
          <h3>Address</h3>
          <p>{{ contactUs.address }}</p>
          <h3>Email</h3>
          <p><a :href="'mailto:' + contactUs.email">{{ contactUs.email }}</a></p>
          <h3>Office Hours</h3>
          <p>{{ contactUs.monday }}</p>
          <p>{{ contactUs.saturday }}</p>
          <p>{{ contactUs.sunday }}</p>
        </div>
        <div class="map-container">
          <!-- Google Map iframe -->
          <iframe
            v-if="contactUs.address"
            :src="`https://www.google.com/maps?q=${encodeURIComponent(contactUs.address)}&output=embed`"
            width="600"
            height="450"
            style="border:0;"
            allowfullscreen
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <!-- Підключаємо компонент форми з заголовком -->
      <FormComponent title="Leave a Message" @formSubmitted="$emit('formSubmitted', $event)" />
    </div>
  </section>
</template>

<script>
import FormComponent from '@/components/FormComponent.vue';

export default {
  name: "ContactSection",
  components: { FormComponent },
  data() {
    return {
      contactUs: {
        address: "Rue Breydel 34-36, 1040 Brussels",
        email: "info@recoveryassist.com",
        monday: "Monday to Friday: 9:00 am to 6:00 pm",
        saturday: "Saturday: 9:00 am to 12 noon",
        sunday: "Closed on Sundays",
      },
    };
  },
};
</script>

<style scoped>
/* Стили залишаються такими самими */
.map-container {
  margin-top: 20px;
}
iframe {
  border: 0;
  width: 100%;
  height: 450px;
}

/* Стили для контактної інформації */
.address {
  margin-bottom: 2rem;
}
</style>
