<template>
  <div id="app">
    <HeaderComponent />
    <HeroComponent  @formSubmitted="openModal"/>
    <ProcessComponent />
    <TypeOfScumComponent />
    <MissionComponent />
    <OurTeamComponent />
    <TestimonialsComponent />
    <FaqComponent />
    <ContactsComponent  @formSubmitted="openModal"/>
    <FooterComponent />
    
    <ChatIcon  ref="chatIcon" @formSubmitted="openModal"/>
    <ModalComponent 
      v-if="showModal" 
      :showModal="showModal" 
      :userName="userName" 
      @close="closeModal"
    />
  </div>
</template>

<script>
import HeaderComponent from './components/Header.vue';
import HeroComponent from './components/Hero.vue';
import ProcessComponent from './components/Process.vue';
import FooterComponent from './components/Footer.vue';
import MissionComponent from './components/Mission.vue';
import TypeOfScumComponent from './components/TypeOfScum.vue';
import OurTeamComponent from './components/OurTeam.vue';
import TestimonialsComponent from './components/Testimonials.vue';
import FaqComponent from './components/Faq.vue';
import ContactsComponent from './components/Contacts.vue';
import ChatIcon from "@/components/ChatIcon.vue";
import ModalComponent from "@/components/ModalComponent.vue";



export default {
  name: 'App',
  components: {
    HeaderComponent,
    HeroComponent,
    ProcessComponent,
    FooterComponent,
    MissionComponent,
    OurTeamComponent,
    TypeOfScumComponent,
    TestimonialsComponent,
    FaqComponent,
    ContactsComponent,
    ChatIcon,
    ModalComponent
  },
  data() {
    return {
      showModal: false,
      userName: '',
    };
  },
  methods: {
    openModal(userName) {
      this.userName = userName;
      this.showModal = true;
      this.$refs.chatIcon.showModal = false;
    },
    closeModal() {
      this.showModal = false;
    }
  }
};

</script>

<style>
@import '~@/assets/css/style.css';
@import '~@/assets/css/media.css';
@import 'intl-tel-input/build/css/intlTelInput.css';
</style>
