<template>
  <div>
    <div class="chat-icon-wrapper" @click="toggleModal">
      <div class="chat-icon">
        <img 
          v-if="!showModal" 
          :src="chatIcon" 
          alt="Chat Icon" 
          width="40" 
          height="40"
        />
        <img 
          v-else 
          :src="closeIcon" 
          alt="Close Icon" 
          width="40" 
          height="40"
        />
      </div>
    </div>

    <!-- Modal with form -->
    <div v-if="showModal" class="modal" @click.self="toggleModal">
      <div class="modal-content">
        <FormComponent 
          title="Chat with Us" 
          @formSubmitted="handleFormSubmitted"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormComponent from "@/components/FormComponent.vue";

export default {
  components: {
    FormComponent,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    chatIcon() {
      return require('@/assets/icon/chat.svg');
    },
    closeIcon() {
      return require('@/assets/icon/close.svg');
    },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    handleFormSubmitted(userName) {
      console.log("TURTURUTU")
      this.showModal = false; // Закриваємо модальне вікно
      this.$emit('formSubmitted', userName); // Передаємо подію у головний компонент
    },
  },
};
</script>

<style scoped>
.chat-icon-wrapper {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.chat-icon {
  width: 60px;
  height: 60px;
  background-color: #007bff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  animation: pulse 2s infinite; 
}

.chat-icon:hover {
  transform: scale(1.1);
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
}
</style>
