<template>
    <!-- SECTION-HERO -->
    <section class="hero" id="home">
      <div class="container">
        <div class="heading-text">
          <h2>{{ heroData.title }}</h2>
          <p>{{ heroData.text }}</p>
        </div>
        <FormComponent title="" @formSubmitted="$emit('formSubmitted', $event)" />
      </div>
      <!-- Payment Icons -->
      <div class="payment-icons">
        <img :src="Visa" alt="Visa">
        <img :src="Master_Card" alt="MasterCard">
        <img :src="Binance" alt="Binance">
        <img :src="Kraken" alt="Kraken">
        <img :src="Bybit" alt="Bybit">
        <img :src="Coinbase" alt="Coinbase">
        <img :src="OKX" alt="OKX">
        <img :src="HTX" alt="HTX">
      </div>
    </section>
    <!-- END SECTION-HERO -->
  </template>
  
  <script>
  import FormComponent from '@/components/FormComponent.vue';
  
  export default {
    name: 'HeroComponent',
    components: { FormComponent },
    data() {
    return {
      heroData: {
        title: "HELPING YOU RECLAIM YOUR MONEY FROM SCAMS WITH RECOVERY ASSIST",
        text: "At Recovery Assist, we understand the distress and frustration that come with falling victim to online fraud. Our mission is to assist individuals who have been scammed in reclaiming their hard-earned money. With a focus on trustworthiness and effective communication, we are dedicated to providing reliable legal solutions for our clients."
      },
      Visa: require('@/img/payment/visa.png'),
      Master_Card: require('@/img/payment/master_card.png'),
      Binance: require('@/img/payment/binance.png'),
      Kraken: require('@/img/payment/kraken.png'),
      Bybit: require('@/img/payment/bybit.svg'),
      Coinbase: require('@/img/payment/coinbase.png'),
      OKX: require('@/img/payment/okx.png'),
      HTX: require('@/img/payment/htx.png'),
    };
}
  };
  </script>
  
  <style scoped>
  .payment-icons {
    margin: 100px 100px 0 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .payment-icons img {
    width: auto;
    flex: 1;
    max-width: 100px; 
  }
  
  @media (max-width: 768px) {
    .payment-icons {
      justify-content: center;
      margin: 0 50px;
    }
  
    .payment-icons img {
      max-width: 80px; 
    }
  }
  </style>
  