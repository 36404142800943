<template>
  <div class="contact-form" id="Form">
    <!-- Overlay when form is submitting -->
<div v-if="isLoading" class="overlay">
  <div class="spinner"></div>
</div>


    <h3>{{ title }}</h3>
    <form @submit.prevent="submitForm">
      <!-- Name Field -->
      <div class="form-group">
        <label for="name">Name</label>
        <input 
          type="text" 
          id="name" 
          v-model="form.name" 
          @blur="validateName" 
          :class="{ 'is-invalid': errors.name }"
          required 
        />
        <div v-if="errors.name" class="error-message">{{ errors.name }}</div>
      </div>

      <!-- Email Field -->
      <div class="form-group">
        <label for="email">Email</label>
        <input 
          type="email" 
          id="email" 
          v-model="form.email" 
          @blur="validateEmail" 
          :class="{ 'is-invalid': errors.email }"
          required 
        />
        <div v-if="errors.email" class="error-message">{{ errors.email }}</div>
      </div>

      <!-- Phone Field -->
      <div class="form-group">
        <label for="phone">Phone Number</label>
        <input 
          type="tel" 
          id="phone" 
          ref="phoneInput"
          v-model="form.phone" 
          @blur="validatePhone" 
          :class="{ 'is-invalid': errors.phone }"
          required 
        />
        <div v-if="errors.phone" class="error-message">{{ errors.phone }}</div>
      </div>

      <!-- Message Field -->
      <div class="form-group">
        <label for="case">Brief Description of the Issue</label>
        <textarea 
          id="case" 
          v-model="form.case" 
          @blur="validateCase" 
          :class="{ 'is-invalid': errors.case }"
          rows="4" 
          required
        ></textarea>
        <div v-if="errors.case" class="error-message">{{ errors.case }}</div>
      </div>

      <!-- Submit Button -->
      <div class="tab-button">
        <button type="submit" class="cta" :disabled="isLoading">Submit</button>
      </div>

      <!-- Error Message -->
      <div v-if="errorMessage" class="notification error">{{ errorMessage }}</div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        case: "",
      },
      iti: null,
      errors: {},
      successMessage: "",
      errorMessage: "",
      isLoading: false,  // Set to true initially to test
    };
  },
  mounted() {
    const input = this.$refs.phoneInput;
    if (input) {
      this.iti = intlTelInput(input, {
        separateDialCode: true,
        initialCountry: "auto",
        geoIpLookup: this.lookupIPLocation,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.8/build/js/utils.js',
      });
    }
  },
  methods: {
    lookupIPLocation(callback) {
      fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
          const countryCode = data.country_code;
          callback(countryCode);
        })
        .catch(() => {
          callback('us');
        });
    },
    validateName() {
      this.errors.name = this.form.name ? '' : 'Please enter your name';
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.errors.email = emailRegex.test(this.form.email) ? '' : 'Invalid email address';
    },
    validatePhone() {
      const phoneNumber = this.form.phone;
      if (!phoneNumber || phoneNumber.length < 7) {
        this.errors.phone = 'Invalid phone number';
      } else {
        this.errors.phone = '';
      }
    },
    validateCase() {
      this.errors.case = this.form.case ? '' : 'Message is required';
    },
    submitForm() {
      this.validateName();
      this.validateEmail();
      this.validatePhone();
      this.validateCase();

      if (Object.values(this.errors).every(error => !error)) {
        this.isLoading = true;  // Start loading
        const countryCode = this.iti.getSelectedCountryData().dialCode;
        const fullPhoneNumber = `+${countryCode} ${this.form.phone}`;

        // First email parameters
        const firstEmailParams = {
          to_name: this.form.name,
          email: this.form.email,
          phone: fullPhoneNumber,
          message: this.form.case,
        };

        // Sending the first email
        axios.post('https://api.ablegalgroups.com/api/send-email/', firstEmailParams, {
          headers: {
            'Template-ID': 'template_ulidzha',  // Passing Template-ID header
          }
        })
        .then(() => {
          // Second email parameters
          const secondEmailParams = {
            to_name: this.form.name,
            email: this.form.email,
            subject: 'Thanks for reaching out!',
            greeting: 'Hi there!',
            body_text: 'One of our legal experts at Recovery Assist will be in touch with you soon to discuss your case.',
            body_text1: 'If you have any questions, feel free to reply to this email.',
            signature: 'Best regards, The Recovery Assist Team',
            company_name: 'Recovery Assist'
          };

          return axios.post('https://api.ablegalgroups.com/api/send-email/', secondEmailParams, {
            headers: {
              'Template-ID': 'template_tl3iowa',  // Passing Template-ID header for second email
            }
          });
        })
        .then(() => {
          this.form = { name: '', phone: '', email: '', case: '' };  // Clear the form
          this.successMessage = "Both emails were sent successfully!";
          this.$emit('formSubmitted', this.form.name); 
        })
        .catch(error => {
          console.error('Error sending emails:', error);
          this.errorMessage = 'There was an error submitting the form. Please try again.';
        })
        .finally(() => {
          this.isLoading = false;  // Stop loading
        });
      } else {
        this.errorMessage = 'Please correct the errors in the form before submitting.';
      }
    }
  }
};
</script>

<style scoped>
/* Overlay and spinner styles */
/* Overlay and spinner styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 8px solid #f3f3f3 !important;
  border-top: 8px solid #007bff !important;
  border-radius: 50% !important;
  width: 60px !important;
  height: 60px !important;
  animation: spin 1s linear infinite !important;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.cta {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta:hover {
  background-color: #0056b3;
  transform: scale(1.1); 
}
</style>
