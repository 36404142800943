<template>
    <transition name="fade">
      <div v-if="showModal" class="modal-overlay" @click="close">
        <div class="modal-content" @click.stop>
          <h3>Excellent!</h3>
          <p>Thank you for submitting the form.</p>   
          <p>A legal advisor from AB Legal Group will contact you shortly for further discussion.</p>
          <p>An email has been sent to your provided address. </p>
            
            <br>
            <span>Sincerely, Recovery Assist</span>
    
            <br>
          <button class="cta" @click="close">Close</button>
        </div>
      </div>
      
    </transition>
  </template>
  
  <script>
  export default {
    data() {
    return {
      user_name: ''
    };},
    props: {
      showModal: Boolean,
      userName: String,
    },
    mounted() {
    this.user_name = this.userName; // Присвоюємо значення пропсу userName до локальної змінної
  },
    methods: {
      close() {
        this.$emit('close');
      }
    }
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 500px;
    width: 90%;
  }
  
  button {
    margin-top: 15px;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  