<template>
  <section class="our-team" id="our_teams">
    <div class="container">
      <div class="section-title">
        <h2>Our Team</h2>
      </div>
      <div class="team-container">
        <div v-for="(member, index) in teamMembers" :key="index" class="team-member">
          <img :src="member.photo" :alt="member.name" class="team-photo" />
          <div class="team-info">
            <h3 class="team-name">{{ member.name }}</h3>
            <p class="team-role">{{ member.role }}</p>
            <p class="team-description">{{ member.desc }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OurTeam',
  data() {
    return {
      teamMembers: [],
    };
  },
  mounted() {
    this.fetchTeamMembers();
  },
  methods: {
    fetchTeamMembers() {
      // Використовуйте статичні дані замість запиту до API
      this.teamMembers = [
      {
          id: 1,
          name: "Jesika Lipsa",
          role: "Attorney",
          desc: "Certified International Expert in Financial Law.",
          photo: require('@/img/team/robert-lipsa.webp') // Використання require для статичних зображень
        },
        {
          id: 2,
          name: "Viktor Novitskiy",
          role: "Attorney",
          desc: "Expert in International Financial Law.",
          photo: require('@/img/team/viktor-novitskiy.png') // Використання require для статичних зображень
        },
        {
          id: 3,
          name: "Jessica Harp",
          role: "Litigation Lawyer",
          desc: "",
          photo: require('@/img/team/jessica-harp.png') // Використання require для статичних зображень
        },
        {
          id: 4,
          name: "Vernon Harris",
          role: "Attorney",
          desc: "Criminal Lawyer, Specialist in International Banking and Tax Law.",
          photo: require('@/img/team/vernon-harris.webp') // Використання require для статичних зображень
        }
      ];
      console.log('Static team members data:', this.teamMembers);
    }
  },
};
</script>

<style scoped>
.our-team {
    padding: 50px 0;
}

.our-team .section-title {
    text-align: center;
    margin-bottom: 30px;
}

.team-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-around;
}

.team-member {
    position: relative;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    width: 100%; 
    max-width: 500px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, filter 0.3s ease;
}

.team-photo {
    width: 100%;
    height: 100%; 
    object-fit: cover;
}

.team-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background: #1a73e885;
    transition: backdrop-filter 0.3s ease, transform 0.3s ease;
}

.team-info h3,
.team-info p {
    margin: 0;
    color: #fff;
}

.team-name {
    font-size: 20px;
    margin-bottom: 10px;
}

.team-role {
    font-size: 16px;
    color: #777;
    margin-bottom: 5px;
}

.team-description {
    font-size: 14px;
    color: #555;
}

.team-member:hover .team-info {
    backdrop-filter: none; 
    transform: translateY(-150%); 
    background: none;
}

/* Додати адаптивність */
@media (max-width: 1062px) {
    .team-member {
        max-width: 400px;
    }
}
@media (max-width: 862px) {
    .team-member {
        max-width: 300px;
    }
    .team-member:hover .team-info {
    transform: translateY(-100%); 
    }
}
@media (max-width: 662px) {
    .team-member {
        max-width: 100%;
    }
    .team-member:hover .team-info {
    transform: translateY(-50%); 
    }
}
</style>

